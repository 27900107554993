<template>
  <div class="product-wrap product-pipe">
    <Header is-active="product" theme="white"></Header>
    <div class="empty-nav"></div>
    <div class="sub-banner banner-pipe">
      <div class="txt"></div>
    </div>
    <div class="white-wrap">
      <div class="txt-box align-ct pd-5 no-indent">
        <p>综合管廊是城市重要新基建项目之一，根据《城镇综合管廊监控与报警系统工程技术标准》GB/T 51274-2017要求，综合管廊需建设管廊环境与设备监控系统。</p>
        <p>通过在管廊防火分区内布设硫化氢、氧气、甲烷、温湿度、液位等传感器，实现对管廊环境内有害气体、含氧量、温湿度实时监测，对积水坑水位实时监测，</p>
        <p>并实现对风机、水泵、照明等控制设备进行联动控制。综合管廊环境与设备监控系统是综合管廊监控与报警系统中的一个子系统，</p>
        <p>主要实现对综合管廊全域内环境与设备的参数、运行状态实施全程监控，</p>
        <p>监控数据通过光环网汇聚到管廊运维管理平台进行集中监控、统一调度、综合运维管理。</p>
      </div>
    </div>
    <div class="grey-wrap">
      <div class="fz-big align-ct"><h4>平台优势</h4></div>
      <div class="ys-pic">
        <img src="../../assets/img/product/ptys.png" alt="平台优势">
      </div>
    </div>
    <div class="white-wrap">
      <div class="fz-big align-ct"><h4>平台架构</h4></div>
      <div class="jg-pic">
        <img src="../../assets/img/product/pipe/pic_1.png" alt="平台架构">
      </div>
    </div>
    <div class="grey-wrap">
      <div class="max-center">
        <div class="fz-big align-ct"><h4>平台功能</h4></div>
        <div class="card-box">
          <el-row :gutter="30">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="card-item">
                <div class="auto-img">
                  <img src="../../assets/img/product/pipe/pic_2.jpg" alt="平台功能">
                </div>
                <div class="txt">
                  <h6>平台功能</h6>
                  <div class="txt-info">
                    实现对管廊内环境参数、对排水、风机、照明、供配电附属设备运行状态、用电参数等进行实时监测。
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="card-item">
                <div class="auto-img">
                  <img src="../../assets/img/product/pipe/pic_3.jpg" alt="平台功能">
                </div>
                <div class="txt">
                  <h6>管廊设备智能控制</h6>
                  <div class="txt-info">
                    当管廊内发生异常时，系统可根据设置的阈值，对排水泵、排风机、报警器等实现智能控制。
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="30">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="card-item">
                <div class="auto-img">
                  <img src="../../assets/img/product/pipe/pic_4.jpg" alt="平台功能">
                </div>
                <div class="txt">
                  <h6>管廊综合监控</h6>
                  <div class="txt-info">
                    各类监控数据及设备运行状态汇集到综合管廊监控中心，实现数据分析、集中展示及安全控制等。
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="card-item">
                <div class="auto-img">
                  <img src="../../assets/img/product/pipe/pic_5.jpg" alt="管廊日常运维">
                </div>
                <div class="txt">
                  <h6>管廊日常运维</h6>
                  <div class="txt-info">
                    系统具备基础监控、运维管理、应急指挥等功能，集成GIS等业界最新技术，实现可视化高效化运维。
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="30">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="card-item">
                <div class="auto-img">
                  <img src="../../assets/img/product/pipe/pic_6.jpg" alt="GIS一张图">
                </div>
                <div class="txt">
                  <h6>GIS一张图</h6>
                  <div class="txt-info">
                    各类监控数据及设备运行状态汇集到综合管廊监控中心，实现数据分析、集中展示及安全控制等。
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="card-item">
                <div class="auto-img">
                  <img src="../../assets/img/product/pipe/pic_7.jpg" alt="报警信息推送">
                </div>
                <div class="txt">
                  <h6>报警信息推送</h6>
                  <div class="txt-info">
                    系统可根据报警信息，进行任务调度，根据报警类型，进行应急处置。可对报警数据进行查看分析。
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>

    </div>
    <div class="white-wrap">
      <div class="max-center pd-5">
        <el-row :gutter="30">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-txt">
              <div class="fz-big align-lt" style="padding-top: 8%"><h4>定制化开发</h4></div>
              <div class="txt-box dzh-box">
                <p class="em">定制的不仅仅是软件和硬件 更是一种理念</p>
                <p>定制化开发是解决企业个性化难题唯一途径，而且是企业快速发展和提高竞争力的最好方法。</p>
                <el-button type="primary" plain class="zdh-btn" @click="handleShow">立即咨询</el-button>
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-pic dzh-pic"></div>
          </el-col>
        </el-row>
        <div class="fz-sm align-ct" style="padding-top: 4%"><h5>定制化特点</h5></div>
        <div class="customized-box">
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-gps"></i></div>
            <p>针对性强</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-bianjie"></i></div>
            <p>操作便捷</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-price"></i></div>
            <p>性价比高</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-service"></i></div>
            <p>服务周到</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-upgrade"></i></div>
            <p>升级方便</p>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
    <BackTop ref="backTop"></BackTop>
  </div>
</template>
<script>
import Header from '../../components/Header.vue'
import Footer from '../../components/Footer.vue'
import BackTop from '../../components/BackTop.vue'

export default {
  metaInfo: {
    meta: [
      {
        name: 'keyWords',
        content: '智慧管廊-产品中心,云昇新控,智慧水务,智慧工厂,自动化系统集成,智慧照明,重庆云昇新控智能科技有限公司'
      },
      {
        name: 'description',
        content: '云昇新控智慧管廊-产品中心,重庆云昇新控智能科技有限公司'
      }
    ]
  },
  components: {
    Header,
    Footer,
    BackTop
  },
  data () {
    return {}
  },
  methods: {
    handleShow () {
      this.$refs.backTop.handleOpen()
    }
  }
}
</script>
<style lang="less" scoped>
@import "../../../public/css/product.less";

.product-pipe {
  .banner-pipe {
    background: url("../../assets/img/product/pipe/banner.jpg") no-repeat center;
    background-size: cover;
    position: relative;
  }
}
</style>
